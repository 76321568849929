<template>
  <tr>
    <td>{{title}}</td>
    <td class="text-left">{{text}}</td>
  </tr>
</template>
<script>

export default {
  name: 'TextTableCell',
  props: {
    title: {},
    value: {},
  },
  computed: {
    text: function () {
      return this.value;
    }
  },
}
</script>
<style>
td {
    width: 50%;
}
</style>