// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#f6bb2e', //colors.teal,
                secondary: colors.amber.lighten3, //colors.grey, // #FFCDD2
                accent: colors.amber.darken4, // #3F51B5
            },
        },
    },
}
export default new Vuetify(opts)
