<template>
  <tr>
    <td>{{title}}</td>
    <td :class="styleClass">{{text}} EUR</td>
  </tr>
</template>
<script>

export default {
  name: 'CurrencyTableCell',
  props: {
    title: {},
    value: {},
    negative: {},
    leftAlign: {},
  },
  computed: {
    styleClass: function () {
        let value = "text-right ";
        if (this.leftAlign) value = "";

      return  value + (this.negative && parseInt(this.value) !== 0 ? "red--text": "");
    },
    text: function () {
      return this.negative && parseInt(this.value) > 0 ? "-" + this.value : this.value;
    }
  },
}
</script>
<style>
td {
    width: 50%;
}
</style>