<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link :to="{ name: 'Beratungsanfragen' }">Beratungsanfragen</router-link></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link :to="{ name: 'FinancialRawData' }">Rohdaten</router-link></v-list-item-title>
          </v-list-item>

<!--          <v-list-item>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-email</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title><router-link :to="{ name: 'Newsletter' }">Newsletter</router-link></v-list-item-title>-->
<!--          </v-list-item>-->

<!--          <v-list-item>-->
<!--            <v-list-item-icon>-->
<!--              <v-icon>mdi-account</v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title><router-link :to="{ name: 'Account' }">Account</router-link></v-list-item-title>-->
<!--          </v-list-item>-->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        absolute
        color="primary"
    >
      <v-app-bar-nav-icon v-if="currentUser !== null" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Brandt Legal Backoffice</v-toolbar-title>

      <v-spacer></v-spacer>
<!--      <v-text-field placeholder="Suchen..." v-if="showSearch"/>-->

<!--      <v-btn icon v-if="currentUser !== null" @click="showSearch = !showSearch">-->
<!--        <v-icon>mdi-magnify</v-icon>-->
<!--      </v-btn>-->

      <!--      <v-btn icon v-if="loggedIn">-->
      <!--        <v-icon>mdi-dots-vertical</v-icon>-->
      <!--      </v-btn>-->

      <v-btn icon v-if="currentUser !== null" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    drawer: false,
    showSearch: false,
  }),
  watch: {
    currentUser(newValue, oldValue) {
      console.log(`User changed from ${oldValue} to ${newValue}`);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('updateUser', null);
      if (this.$router.currentRoute.name !== 'Login') {
        this.$router.replace('/login');
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  mounted() {
    let self = this;
    axios.get(`${process.env.VUE_APP_ENDPOINT}/admin/account`, {timeout: 20000})
        .then(function (response) {
          console.log("account success", response);
          self.$store.dispatch('updateUser', response.data);
          if (self.$router.currentRoute.name === 'Login') {
            self.$router.replace('/');
          }
        })
        .catch(function (error) {
          console.log("account error", error);
          self.$store.dispatch('updateUser', null);
          if (self.$router.currentRoute.name !== 'Login') {
            self.$router.replace('/login');
          }
        })
    ;
  }
}
</script>

<style>

</style>
